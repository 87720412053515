<template>
  <div class="container">
    <div
      @click="goback"
      style="font-size: 14px; margin-bottom: 10px; cursor: pointer; width: 50px"
    >
      <i class="el-icon-back"></i>
      返回
    </div>
    <el-form :model="form" :rules="rules" ref="form" style="width: 600px">
      <el-form-item label="终端编号" prop="appid" label-width="130px">
        <el-select v-model="form.appid">
          <el-option
            v-for="item in applist"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否重复" prop="is_repeat" label-width="130px">
        <el-select v-model="form.is_repeat">
          <el-option label="重复" :value="1"></el-option>
          <el-option label="不重复" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="模板名称" prop="name" label-width="130px">
        <el-input v-model="form.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label-width="130px">
        <el-button type="primary" @click="Validate(Addconfirm)">{{
          edit
        }}</el-button>
      </el-form-item>
    </el-form>

    <div v-if="edit == '更新'">
      <h4 style="margin: 10px 0">邮件模板</h4>
      <router-link
        style="float: right; margin-bottom: 10px"
        :to="'/message/emailmsgadd/' + templateCode + '&' + 0"
      >
        <el-button type="primary" size="mini">新建邮件模板</el-button>
      </router-link>
      <el-table border v-bind:data="emaillist">
        <el-table-column label="标题">
          <template v-slot="scope">
            <span>{{ scope.row.title }}</span>
          </template>
        </el-table-column>
        <el-table-column label="邮件附件">
          <template v-slot="scope">
            <el-button
              v-if="scope.row.attachment"
              type="text"
              size="mini"
              @click="Lookattachment(scope.row.attachment)"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="内容">
          <template v-slot="scope">
            <el-button
              type="text"
              size="mini"
              @click="LookContent(scope.row.content)"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="语言简写">
          <template v-slot="scope">
            <span>{{ scope.row.language_code }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200px">
          <template v-slot="scope">
            <router-link
              style="margin-right: 10px"
              :to="
                '/message/emailmsgadd/' + templateCode + '&' + scope.row.code
              "
            >
              <el-button type="info" size="mini">修改</el-button>
            </router-link>
            <el-button type="danger" size="mini" @click="DeleteEmail(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="HandleEmailChange"
        v-model:currentPage="emailSearch.page"
        :page-size="emailSearch.limit"
        layout="total, prev, pager, next"
        :total="emailtotal"
      >
      </el-pagination>

      <!-- 图文 -->
      <h4 style="margin: 20px 0">图文模板</h4>
      <router-link
        style="float: right; margin-bottom: 10px"
        :to="'/message/imgmsgadd/' + templateCode + '&' + 0"
      >
        <el-button type="primary" size="mini">新建图文模板</el-button>
      </router-link>
      <el-table border v-bind:data="imglist">
        <el-table-column label="标题">
          <template v-slot="scope">
            <span>{{ scope.row.title }}</span>
          </template>
        </el-table-column>
        <el-table-column label="消息概要">
          <template v-slot="scope">
            <span>{{ scope.row.abstract }}</span>
          </template>
        </el-table-column>
        <el-table-column label="内容">
          <template v-slot="scope">
            <el-button
              type="text"
              size="mini"
              @click="LookContent(scope.row.content)"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="语言简写">
          <template v-slot="scope">
            <span>{{ scope.row.language_code }}</span>
          </template>
        </el-table-column>
        <el-table-column label="预览图片">
          <template v-slot="scope">
            <el-button
              type="text"
              size="mini"
              @click="LookImg(scope.row.preview_img)"
              >查看</el-button
            >
            <!-- <span>{{ scope.row.preview_img }}</span> -->
          </template>
        </el-table-column>
        <el-table-column label="推送图片">
          <template v-slot="scope">
            <el-button
              type="text"
              size="mini"
              @click="LookImg(scope.row.push_img)"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200px">
          <template v-slot="scope">
            <router-link
              style="margin-right: 10px"
              :to="'/message/imgmsgadd/' + templateCode + '&' + scope.row.code"
            >
              <el-button type="info" size="mini">修改</el-button>
            </router-link>
            <el-button type="danger" size="mini" @click="DeleteImg(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="HandleImgChange"
        v-model:currentPage="imgSearch.page"
        :page-size="imgSearch.limit"
        layout="total, prev, pager, next"
        :total="imgtotal"
      >
      </el-pagination>

      <!-- 文本 -->
      <h4 style="margin: 20px 0">文本模板</h4>
      <router-link
        style="float: right; margin-bottom: 10px"
        :to="'/message/textmsgadd/' + templateCode + '&' + 0"
      >
        <el-button type="primary" size="mini">新建文本模板</el-button>
      </router-link>
      <el-table border v-bind:data="textlist">
        <el-table-column label="内容">
          <template v-slot="scope">
            <span>{{
              scope.row.content != 0 ? scope.row.content.substr(0, 10) : ""
            }}</span>
            <span v-if="scope.row.content.length > 10">…</span>
          </template>
        </el-table-column>
        <el-table-column label="语言">
          <template v-slot="scope">
            <span>{{ scope.row.language_code }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200px">
          <template v-slot="scope">
            <router-link
              style="margin-right: 10px"
              :to="'/message/textmsgadd/' + templateCode + '&' + scope.row.code"
            >
              <el-button type="info" size="mini">修改</el-button>
            </router-link>
            <el-button type="danger" size="mini" @click="DeleteText(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="HandleTextChange"
        v-model:currentPage="textSearch.page"
        :page-size="textSearch.limit"
        layout="total, prev, pager, next"
        :total="texttotal"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="附件列表"
      v-model="attachVisible"
      width="600px"
      style="text-align: center"
    >
      <el-table border v-bind:data="attachmentList">
        <el-table-column label="名称">
          <template v-slot="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="key">
          <template v-slot="scope">
            <span>{{ scope.row.key }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template v-slot="scope">
            <el-button type="info" size="mini" @click="DownAttach(scope.row)"
              >预览</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="attachVisible = false">取 消</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog
      title="查看"
      v-model="dialogFormVisible"
      width="600px"
      style="text-align: center"
    >
      <el-image
        v-if="imgShow"
        style="width: 300px; height: 300px"
        :src="imgurl"
      ></el-image>
      <div id="editorone" ref="editorone" v-if="contentShow"></div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: "",
  props: {},
  setup() {
    return {};
  },
  mixins: [],
  components: {},
  data() {
    return {
      templateCode: "",
      form: {
        code: "",
        appid: "",
        is_repeat: "",
        name: "",
      },
      rules: {
        appid: [{ required: true, message: "请输入终端编号", trigger: "blur" }],
        is_repeat: [
          { required: true, message: "请输入是否重复", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入模板名称", trigger: "blur" }],
      },
      edit: "",
      applist: [],
      emailSearch: {
        page: 1,
        limit: 10,
        kname: "",
        code: "",
      },
      emaillist: [],
      emailtotal: 0,
      imgSearch: {
        page: 1,
        limit: 10,
        kname: "",
        code: "",
      },
      imglist: [],
      imgtotal: 0,
      textSearch: {
        page: 1,
        limit: 10,
        kname: "",
        code: "",
      },
      textlist: [],
      texttotal: 0,
      dialogFormVisible: false,
      imgurl: "",
      previewcontent: "",
      imgShow: false,
      contentShow: false,
      attachVisible: false,
      attachmentList: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    Lookattachment(item) {
      this.attachmentList = JSON.parse(item);
      this.attachVisible = true;
    },
    DownAttach(item) {
      this.api.S3down({ key: item.key }).then((res) => {
        console.log(res.data.data.get_url, "附件");
        let imgurl = res.data.data.get_url;
        let link = document.createElement("a");
        link.setAttribute("href", imgurl);
        link.setAttribute("target", "_blank");
        link.click();
      });
    },
    LookImg(item) {
      this.GetImgText(item, "img");
    },
    LookContent(item) {
      this.GetImgText(item, "content");
    },
    GetImgText(item, which) {
      this.api.S3down({ key: item }).then((res) => {
        if (res.data.code == 200) {
          this.dialogFormVisible = true;
          if (which == "img") {
            this.imgurl = res.data.data.get_url;
            this.imgShow = true;
            this.contentShow = false;
          } else if (which == "content") {
            this.imgShow = false;
            this.contentShow = true;
            this.$nextTick(() => {
              document.getElementById("editorone").innerHTML = "";
            });
            var urlReg =
              /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/;
            this.$axios
              .get(
                "/aws" +
                  res.data.data.get_url.split(
                    urlReg.exec(res.data.data.get_url)[0]
                  )[1]
              )
              .then((res) => {
                if (res.status === 200) {
                  //  this.$refs.editorone.setHtml(res.data)
                  document.getElementById("editorone").innerHTML = res.data;
                }
              });
          }
          return;
        }
        this.$message.error("下载S3图片失败:" + res.data.msg);
      });
    },
    // 邮件操作
    HandleEmailChange() {
      this.emailSearch.page = val;
      this.GetEmailList();
    },
    GetEmailList() {
      this.api
        .TemplateEmailList({
          page: this.emailSearch.page,
          limit: this.emailSearch.limit,
          kname: this.emailSearch.kname,
          code: this.emailSearch.code,
          message_template_head_code: this.templateCode,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.emaillist = res.data.data.data;
            this.emailtotal = res.data.data.page_info.total;
            this.GetImgList();
            return;
          }
          this.$message.error("获取失败" + res.data.msg);
        });
    },
    DeleteEmail(item) {
      this.$confirm("是否删该邮件模板?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 删除请求
          this.api
            .TemplateEmailDelete({
              code: item.code,
            })
            .then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.GetEmailList();
                return;
              }
              this.$message.error("删除失败" + res.data.msg);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    // 图文操作
    HandleImgChange() {
      this.imgSearch.page = val;
      this.GetImgList();
    },
    GetImgList() {
      this.api
        .TemplateImgList({
          page: this.imgSearch.page,
          limit: this.imgSearch.limit,
          kname: this.imgSearch.kname,
          code: this.imgSearch.code,
          message_template_head_code: this.templateCode,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.imglist = res.data.data.data;
            this.imgtotal = res.data.data.page_info.total;
            this.GetTextList();
            return;
          }
          this.$message.error("获取失败" + res.data.msg);
        });
    },
    DeleteImg(item) {
      this.$confirm("是否删该图文模板?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 删除请求
          this.api
            .TemplateImgDelete({
              code: item.code,
            })
            .then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.GetImgList();
                return;
              }
              this.$message.error("删除失败" + res.data.msg);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    // 文本操作
    HandleTextChange() {
      this.textSearch.page = val;
      this.GetTextList();
    },
    GetTextList() {
      this.api
        .TemplateTextList({
          page: this.textSearch.page,
          limit: this.textSearch.limit,
          kname: this.textSearch.kname,
          code: this.textSearch.code,
          message_template_head_code: this.templateCode,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.textlist = res.data.data.data;
            this.texttotal = res.data.data.page_info.total;
            return;
          }
          this.$message.error("获取失败" + res.data.msg);
        });
    },
    DeleteText(item) {
      this.$confirm("是否删该文本模板?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 删除请求
          this.api
            .TemplateTextDelete({
              code: item.code,
            })
            .then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.GetTextList();
                return;
              }
              this.$message.error("删除失败" + res.data.msg);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    GetAppList() {
      this.api
        .ClientList({
          page: 1,
          limit: 100,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.applist = res.data.data.data;
            return;
          }
          this.$message.error("获取失败" + res.data.msg);
        });
    },
    GetInfo() {
      this.api
        .TemplateDetail({
          code: this.templateCode,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.form = res.data.data;
            this.GetEmailList();
            return;
          }
          this.$message.error("获取失败" + res.data.msg);
        });
    },
    Validate: function (call) {
      this.$refs["form"].validate((res) => {
        if (res && call) {
          call();
        }
      });
    },
    Addconfirm() {
      this.api.TemplateUpdate(this.form).then((res) => {
        if (res.data.code == 200) {
          if (this.edit == "新增") {
            this.form = {};
          }
          this.$confirm(this.edit + "成功,是否返回列表页?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.goback();
          });
          return;
        }
        this.$message.error(this.edit + "失败" + res.data.msg);
      });
    },
    goback() {
      this.$router.push("/message/templatemessage");
    },
  },
  filters: {},
  mounted() {
    this.templateCode = this.$route.params.code;
    if (this.templateCode == 0) {
      this.edit = "新增";
    } else {
      this.edit = "更新";
      this.GetInfo();
    }
  },
  created() {
    this.GetAppList();
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus"></style>
